/* google font imports */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

body {
  font-family: 'Playfair Display', serif;

}

.Cocktails {
  font-family: 'Oswald', sans-serif;
}

.App {
  text-align: center;
}

.disp {
  display: none;
}

/* nav bar */
.color-navbar {
  font-family: 'Playfair Display', serif;
  font-size: 4vh;
  color: #AC1010;


}

.buttonText {
  float: right;
  padding-top: 1vh;
  font-size: 2.5vh;

}

.svgLogo {
  padding-top: 2vh;
  padding-bottom: 0vh;
  height: 9.5vh;
}

.navHeader {
  margin-left: auto;
}

/* cocktaillist cards */
.thumb {
  height: 50vh;
  width: 30vw;
}

.btn-danger {
  background-color: #AC1010;
}

.card-header {
  border-bottom: none;
  background-color: white;
}

.card-Title {
  height: 10vh;
  font-size: 1.3rem;
}

.card-spacing {
  margin-left: 4.3vw;
  margin-bottom: 2.2vh;
  margin-top: 2.2vh;
}

/* landing page classes */
.landingHeading {
  color: #AC1010;
  font-family: 'Oswald', sans-serif;
  font-size: 10vh;
  padding-top: 6vh;
  text-align: center;
}

.landingImage {

  height: 50vh;
}

.landingText {

  padding-left: 1.5vw;
  font-size: 2.4vh;
  color: black;
  text-align: left;
}

.spinner {
  display: flex;
  justify-content: center;
  align-content: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-link {
  color: #AC1010 !important;
  /* border: 1px solid grey */

}

.page-link:hover {
  color: #da1d1d;
  border: none;

}

.page-link>.active>a {
  border: solid 1px #AC1010 !Important;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2vw;
  justify-content: center;
}

.choice {
  margin-left: 4.3vw;
}

.page-item>.active>a {
  border: solid 1px #AC1010 !Important;
}

@media (max-width: 576px) {
  .card-spacing {
    margin-left: 0;
    margin-bottom: 2.2vh;
    margin-top: 2.2vh;
    margin-right: 0;
  }
}

/* basics pages */
/* .basicsImage{
  height:40vh;
  width:auto;
 
} */
.basicsText {
  color: #AC1010;
  font-family: 'Oswald', sans-serif;
  font-size: 5vh;
  padding-right: 5px;
  padding-left: 15px;


}

.topText {
  text-align: center;
}

.card>a {
  text-decoration: none;
}

/* favorites page */
.favoriteHeader {
  text-align: center;
}

.userImage {
  border-radius: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}